.main-content {
  min-height: 100vh;
  position: relative;
  background: #f4f5f7;
}


.container-fluid {
  width: 100%;
  padding-right: var(--bs-gutter-x, .75rem);
  padding-left: var(--bs-gutter-x, .75rem);
  margin-right: auto;
  margin-left: auto;
}
